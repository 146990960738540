import { Layout } from '../components/Layout'
import { HeadFC } from 'gatsby'
import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { SEO } from '../components/SEO'

const ProjectsPage = () => {
  return (
    <Layout>
      <div className="text-box">
        <h1>Projets</h1>
        <h3>Festival des Musicales de Redon, été 2021</h3>

        <div className="image-text">
          <div>
            <p>
              En 2019, Henri de Sonis, directeur du festival de musique classique Les Musicales de Redon, me propose d’y
              participer dans le cadre de la journée sur l’Inde.
            </p>
            <p>J’ai saisi cette occasion pour proposer à Kalpana de partager la scène avec moi.</p>
            <p>Reporté pour cause de pandémie, le festival a finalement eu lieu en juillet 2021.</p>
          </div>
          <div className="image">
            <StaticImage
              src={'../images/project-1.webp'}
              placeholder="blurred"
              formats={['auto', 'webp', 'avif']}
              alt="projects"
              transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
            />
            <figcaption>Photo : Irénée de Poulpiquet</figcaption>
          </div>
        </div>

        <div className="image-text">
          <div className="image">
            <StaticImage
              src={'../images/project-2.webp'}
              placeholder="blurred"
              formats={['auto', 'webp', 'avif']}
              alt="projects"
              transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
            />
            <figcaption> Photo : Francis Payol</figcaption>
          </div>
          <div>
            <p>
              Une table ronde sur le thème de la relation maître à disciple dans l’enseignement de l’art en Inde a réuni
              les musiciens Alexis Weisgerber (tabla) et Thomas Jacquot (sitar) et les danseuses, avant un récital
              classique de bharata natyam puis un concert de musique classique du nord de l’Inde.
            </p>
          </div>
        </div>

        <h3>Danse avec les fous, avec Claude Niedergang, Laure Fouard et la troupe des fous, été 2017</h3>

        <div className="image-text">
          <div>
            <p>ATD Quart-Monde a monté un village éphémère place de la Bastille, à l’été 2017.</p>
            <p>
              Cela a été l’occasion pour Claude, Laure et moi de mettre en mouvement sur scène sur notre longue amitié,
              et de la raconter à travers la danse.
            </p>
          </div>
          <div className="image">
            <StaticImage
              src={'../images/project-3.webp'}
              placeholder="blurred"
              formats={['auto', 'webp', 'avif']}
              alt="projects"
              transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
            />
            <figcaption>Photo : Kalpana Métayer</figcaption>
          </div>
        </div>

        <h3>Recherches en improvisation, avec Agnès Guipont et David Monnin Berlin, 2009-2010</h3>

        <div className="image-text">
          <div className="image">
            <StaticImage
              src={'../images/project-4.webp'}
              placeholder="blurred"
              formats={['auto', 'webp', 'avif']}
              alt="projects"
              transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
            />
            <figcaption>Photo : Rebekka Heim</figcaption>
          </div>
          <div>
            <p>
              David est musicien électronique, Agnès est chanteuse et pianiste : très vite après mon arrivée à Berlin,
              nous avons démarré des répétitions faites d’improvisations longues et intenses, qui m’ont permis
              d’explorer les possibilités d’expression et de narration qu’offre la danse, en dehors du cadre du bharata
              natyam.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProjectsPage

export const Head: HeadFC = () => <SEO title="Lakshmi - Projets" pathname="/projets"></SEO>
